<template>
  <div class="mr-4">
    <v-subheader>Job Infomation</v-subheader>
    <v-row>
      <v-col cols="4">
        <div class="file-wrapper"
          @dragover.prevent
          @drop.prevent>
          <div @drop="handleFileDrop">
            <input type="file"
              name="ids-file-input"
              @change="handleFileInput" />
          </div>
          <v-row>
            <v-icon class="iconUpload"
              size="100px">mdi-cloud-upload-outline</v-icon>
          </v-row>
          <v-row>
            Drag file create job here or click to upload
          </v-row>
        </div>
            <ul>
              <li v-for="(file, index) in files"
                :key="index"
                class="fileList">
                {{ file.name }} ({{ file.size / 1000 }} Kb. | total : {{ jobTotal.totalEstimateIds }}) 
                <button @click="removeFile(index)"
                  title="Remove">X</button>
              </li>
            </ul>
      </v-col>
      <v-col cols="8">
          <v-text-field
            label="Topic of Boardcast"
            v-model="jobInfo.name"
            hint="Topic is valid for 1 day"
            persistent-hint
            dense
            outlined
            required />
        <v-row>
          <v-col cols="6"
              class="mt-2">
            <v-menu
              v-model="jobDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px">
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  dense
                  label="Date Start Job"
                  hint="DD/MM/YYYY format"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on" />
              </template>
              <v-date-picker
                v-model="date"
                no-title
                @input="jobDate = false" />
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-text-field
              class="pt-0"
              v-model="time"
              :rules="[rules.timeFormat]"
              label="Job Time"
              hint="sample format time 09:00"
              persistent-hint
              prepend-icon="mdi-clock-time-four-outline" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as moment from 'moment';

export default {
  name: 'loadJobsInfo',
  props: {
    jobInfo: {
      type: Object
    }
  },
  data: (vm) => ({
    date: moment().format('YYYY-MM-DD'),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    jobDate: false,
    jobTime: '',
    time: null,
    ids: '',
    files: [],
    rules: {
      timeFormat: value => {
          const pattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
          return (
            pattern.test(value) ||
            'Please, check time 24Hr. patten. Ex: "09:00", "13:00"'
          );
        },
    }
  }),
  computed: {
    computedDateFormatted() {
      this.jobInfo.dateJob = this.formatDate(this.date);
      return this.formatDate(this.date);
    },
    // ids: {
    //   get() {
    //     return this.jobInfo.ids;
    //   },

    //   set(value) {
    //     const idsData = value.split(',');
    //     this.jobInfo.ids = idsData;
    //     this.jobInfo.totalEstimateIds = idsData.length;
    //   }
    // },

    ...mapGetters({
      jobTotal: 'jobManagement/GET_TOTAL_JOB'
    })
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },

    time(val) {
      const dayStart = new Date(`${this.date}T${val}`);

      this.jobInfo.startTime = Date.parse(dayStart);
      this.jobInfo.endTime = this.jobInfo.startTime + (1000 * 10);
    },

    ids(val) {
      this.jobInfo.ids = val.split(',');
    }
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    handleFileDrop(e) {
      const droppedFiles = e.dataTransfer.files;

      // eslint-disable-next-line no-useless-return
      if (!droppedFiles) return;

      ([...droppedFiles]).forEach(f => {
        this.files.push(f);
      })
    },

    handleFileInput(e) {
      // eslint-disable-next-line prefer-destructuring
      const files = e.target.files;

      // eslint-disable-next-line no-useless-return
      if (!files) return;

      const fd = new FormData();
      ([...files]).forEach(f => {  
        this.files.push(f);
        fd.append('file', f)
      });


      this.$store.dispatch('jobManagement/UPLOAD_FILE_JOBS', fd);
    },

    removeFile(fileKey){
      this.files.splice(fileKey, 1)
    }
  },
}
</script>

<style lang="scss" scoped>
  .file-wrapper {
    text-align: center;
    margin: auto auto;
    border-style: dashed;
    border-width: 1px;
    border-radius: 5px;
    min-width: 270px;
    height: 150px;
    vertical-align: middle;
    display: table-cell;
    position: relative;
    overflow: hidden; 
      input {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        opacity: 0.0;
        filter: alpha(opacity=0);
        font-size: 300px;
        height: 200px;
        z-index: 1;
      }

      .iconUpload {
        margin: auto auto;
        z-index: 0;
      }
  }

  .fileList {
    color: #039BE5;
    list-style-type: none;
  }

</style>